import { IoMdClose } from "react-icons/io";
import { FC } from "react";

import VideoStream from "../components/VideoStream";
import Modal from "./Modal"

type CameraModalProps = {
    onClose: () => void;
    websocketUrl: string;
}

const CameraModal: FC<CameraModalProps> = ({ onClose, websocketUrl }) => {
    return (
        <Modal bodyStyles={{ borderRadius: '12px' }} customBodyClasses="bg-textColor max-w-[700px]" onClose={onClose}>
            <div className="flex flex-col items-center w-full gap-4">
                <h5 className="text-[22px] leading-[28px] font-semibold flex items-center justify-between w-full">
                    Перегляд камери
                    <IoMdClose className="cursor-pointer" onClick={onClose} size={28} />
                </h5>
                <VideoStream websocketUrl={websocketUrl} />
            </div>
        </Modal>
    )
}

export default CameraModal
