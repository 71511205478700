import { FC, useEffect, useRef } from "react";

type VideoStreamProps = {
    websocketUrl: string;
};

const VideoStream: FC<VideoStreamProps> = ({ websocketUrl }) => {
    const canvasRef = useRef<HTMLCanvasElement>(null);

    useEffect(() => {
        const canvas = canvasRef.current;

        if (!canvas) return;

        const ctx = canvas.getContext("2d");

        if (!ctx) return;

        const socket = new WebSocket(websocketUrl);

        socket.binaryType = "arraybuffer";

        socket.onmessage = (event) => {
            const imageBlob = new Blob([event.data], { type: "image/jpeg" });
            const img = new Image();

            img.onload = () => {
                const canvasAspect = canvas.width / canvas.height;
                const imgAspect = img.width / img.height;

                let drawWidth, drawHeight, offsetX, offsetY;

                if (imgAspect > canvasAspect) {
                    drawWidth = canvas.width;
                    drawHeight = (canvas.width / img.width) * img.height;
                    offsetX = 0;
                    offsetY = (canvas.height - drawHeight) / 2;
                } else {
                    drawHeight = canvas.height;
                    drawWidth = (canvas.height / img.height) * img.width;
                    offsetX = (canvas.width - drawWidth) / 2;
                    offsetY = 0;
                }

                ctx.clearRect(0, 0, canvas.width, canvas.height);
                ctx.drawImage(img, offsetX, offsetY, drawWidth, drawHeight);
            };

            img.src = URL.createObjectURL(imageBlob);
        };

        return () => {
            socket.close();
        };
    }, [websocketUrl]);

    return (
        <div className="rounded-xl overflow-hidden">
            <canvas
                ref={canvasRef}
                width={660}
                height={371}
            ></canvas>
        </div>
    );
};

export default VideoStream;