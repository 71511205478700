import { useEffect, useState } from "react"

import { useOpenBarrierMutation } from "../api/appApi"

import CameraModal from "../modals/CameraModal"
import Title from "./common/Title"

const ParkingControl = () => {
    const [cameraToView, setCameraToView] = useState<string | null>(null)
    const [opened, setOpened] = useState<boolean>(false)

    const [openBarrier] = useOpenBarrierMutation()

    const handleViewCamera = (cameraNumber: number) => {
        setCameraToView(`wss://parking-management-system.sp-lutsk.com/ws/stream/${cameraNumber}/`)
    }

    const handleOpenGates = () => {
        openBarrier().then((res) => {
            if ("error" in res) {
                return
            } else {
                setOpened(true)
            }
        })
    }

    useEffect(() => {
        if (opened) {
            setTimeout(() => {
                setOpened(false)
            }, 15000)
        }
    }, [opened])

    return (
        <div className="flex flex-col p-6 py-8 gap-4">
            <Title title="Керування" />
            <div className="flex flex-col gap-4">
                <h5>
                    В цій панелі можна керувати парковкою, відчиняти шлагбаум.
                </h5>
                <div className="w-full h-[1px] bg-gray-200"></div>
                <p>
                    Переглянути відео з камер парковки.
                </p>
                <div className="flex items-center gap-3">
                    <button onClick={() => handleViewCamera(2)} className="px-4 h-[44px] flex items-center justify-center rounded-lg bg-buttonColor hover:bg-buttonHoverColor transition-colors duration-300 cursor-pointer gap-2 text-base font-semibold select-none w-fit min-w-[200px] disabled:opacity-50 disabled:hover:bg-buttonColor">
                        Камера заїзду
                    </button>
                    <button onClick={() => handleViewCamera(1)} className="px-4 h-[44px] flex items-center justify-center rounded-lg bg-buttonColor hover:bg-buttonHoverColor transition-colors duration-300 cursor-pointer gap-2 text-base font-semibold select-none w-fit min-w-[200px] disabled:opacity-50 disabled:hover:bg-buttonColor">
                        Камера виїзду
                    </button>
                    <button onClick={() => handleViewCamera(3)} className="px-4 h-[44px] flex items-center justify-center rounded-lg bg-buttonColor hover:bg-buttonHoverColor transition-colors duration-300 cursor-pointer gap-2 text-base font-semibold select-none w-fit min-w-[200px] disabled:opacity-50 disabled:hover:bg-buttonColor">
                        Камера парковки
                    </button>
                </div>
                <div className="w-full h-[1px] bg-gray-200"></div>
                <p>
                    Натисніть на кнопку нижче, щоб відчинити шлагбаум.
                </p>
                <button disabled={opened} onClick={handleOpenGates} className="px-4 h-[44px] flex items-center justify-center rounded-lg bg-buttonColor hover:bg-buttonHoverColor transition-colors duration-300 cursor-pointer gap-2 text-base font-semibold select-none w-fit min-w-[200px] disabled:opacity-50 disabled:hover:bg-buttonColor">
                    {opened ? 'Відчинено' : 'Відчинити шлагбаум'}
                </button>
            </div>
            {cameraToView && (
                <CameraModal
                    websocketUrl={cameraToView}
                    onClose={() => setCameraToView(null)}
                />
            )}
        </div>
    )
}

export default ParkingControl