import { FaCar, FaCreditCard, FaParking, FaSitemap, FaUsers } from "react-icons/fa"
import { MdOutlineLocalCarWash } from "react-icons/md"
import { BiSolidDashboard } from "react-icons/bi"
import { RiPagesFill } from "react-icons/ri"
import { IoIosSwitch } from "react-icons/io"
import { NavLink } from "react-router-dom"

import { useAuthContext } from "../contexts/AuthContext"
import { useAppSelector } from "../redux/hooks"

const Navigation = () => {
    const userInfo = useAppSelector((state) => state.app.userInfo)

    const authCtx = useAuthContext()

    const isAdmin = userInfo?.role === 'ADMIN'

    return (
        <div className="max-w-[260px] w-full bg-backgroundColor flex flex-col justify-between py-6">
            <div className="flex flex-col gap-1">
                <h5 className="w-full text-center text-textColor text-[22px] leading-[28px] font-semibold mb-4">
                    Панель Керування
                </h5>
                {isAdmin && (
                    <>
                        <NavLink to='' className={(({ isActive }) => `flex items-center ps-6 pe-2 h-[50px] gap-1 text-[18px] leading-[24px] font-semibold cursor-pointer transition-colors ${isActive ? 'text-buttonColor bg-backgroundSecondaryColor' : 'text-textColor'}`)}>
                            <div className="w-[30px] flex justify-start">
                                <BiSolidDashboard size={25} />
                            </div>
                            Огляд
                        </NavLink>
                        <NavLink to='users' className={(({ isActive }) => `flex items-center ps-6 pe-2 h-[50px] gap-1 text-[18px] leading-[24px] font-semibold cursor-pointer transition-colors ${isActive ? 'text-buttonColor bg-backgroundSecondaryColor' : 'text-textColor'}`)}>
                            <div className="w-[30px]">
                                <FaUsers size={23} />
                            </div>
                            Користувачі
                        </NavLink>
                    </>
                )}
                <NavLink to='parkings' className={(({ isActive }) => `flex items-center ps-6 pe-2 h-[50px] gap-1 text-[18px] leading-[24px] font-semibold cursor-pointer transition-colors ${isActive ? 'text-buttonColor bg-backgroundSecondaryColor' : 'text-textColor'}`)}>
                    <div className="w-[30px]">
                        <FaParking size={23} />
                    </div>
                    Парковки
                </NavLink>
                {isAdmin && (
                    <NavLink to='cars' className={(({ isActive }) => `flex items-center ps-6 pe-2 h-[50px] gap-1 text-[18px] leading-[24px] font-semibold cursor-pointer transition-colors ${isActive ? 'text-buttonColor bg-backgroundSecondaryColor' : 'text-textColor'}`)}>
                        <div className="w-[30px]">
                            <FaCar size={23} />
                        </div>
                        Автомобілі
                    </NavLink>
                )}
                <NavLink to='payments' className={(({ isActive }) => `flex items-center ps-6 pe-2 h-[50px] gap-1 text-[18px] leading-[24px] font-semibold cursor-pointer transition-colors ${isActive ? 'text-buttonColor bg-backgroundSecondaryColor' : 'text-textColor'}`)}>
                    <div className="w-[30px]">
                        <FaCreditCard size={23} />
                    </div>
                    Оплати
                </NavLink>
                <NavLink to='parking-events' className={(({ isActive }) => `flex items-center ps-6 pe-2 h-[50px] gap-1 text-[18px] leading-[24px] font-semibold cursor-pointer transition-colors ${isActive ? 'text-buttonColor bg-backgroundSecondaryColor' : 'text-textColor'}`)}>
                    <div className="w-[30px]">
                        <FaSitemap size={23} />
                    </div>
                    Журнал Парковок
                </NavLink>
                {isAdmin && (
                    <>
                        <NavLink to='system-events' className={(({ isActive }) => `flex items-center ps-6 pe-2 h-[50px] gap-1 text-[18px] leading-[24px] font-semibold cursor-pointer transition-colors ${isActive ? 'text-buttonColor bg-backgroundSecondaryColor' : 'text-textColor'}`)}>
                            <div className="w-[30px]">
                                <RiPagesFill size={23} />
                            </div>
                            Журнал Системи
                        </NavLink>
                        <NavLink to='carwash' className={(({ isActive }) => `flex items-center ps-6 pe-2 h-[50px] gap-1 text-[18px] leading-[24px] font-semibold cursor-pointer transition-colors ${isActive ? 'text-buttonColor bg-backgroundSecondaryColor' : 'text-textColor'}`)}>
                            <div className="w-[30px]">
                                <MdOutlineLocalCarWash size={23} />
                            </div>
                            Мийка
                        </NavLink>
                        <NavLink to='control' className={(({ isActive }) => `flex items-center ps-6 pe-2 h-[50px] gap-1 text-[18px] leading-[24px] font-semibold cursor-pointer transition-colors ${isActive ? 'text-buttonColor bg-backgroundSecondaryColor' : 'text-textColor'}`)}>
                            <div className="w-[30px]">
                                <IoIosSwitch size={23} />
                            </div>
                            Керування
                        </NavLink>
                    </>
                )}
            </div>
            <button
                onClick={() => authCtx?.signOutUser()}
                className="bg-textColor h-[46px] rounded-lg font-semibold text-[18px] leading-[26px] w-[calc(100%-32px)] ms-4 hover:bg-whiteButtonHoverColor transition-colors duration-500"
            >
                Вийти з акаунту
            </button>
        </div >
    )
}

export default Navigation