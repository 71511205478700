import { IoMdClose } from "react-icons/io"
import { FC, useState } from "react"

import { convertDateToLocalFormat } from "../utils/functions"
import { useGetCarSessionsQuery } from "../api/appApi"
import { TCar } from "../utils/types"

import Modal from "./Modal"

type CarSessionsModalProps = {
    onClose: () => void,
    carInfo: TCar
}

const CarSessionsModal: FC<CarSessionsModalProps> = ({ onClose, carInfo }) => {
    const [activePeriod, setActivePeriod] = useState<number | null>(null)

    const { data } = useGetCarSessionsQuery({
        id: carInfo.id,
        daysRange: activePeriod
    })

    return (
        <Modal
            bodyStyles={{ borderRadius: '12px' }}
            customBodyClasses="bg-textColor max-w-[600px]"
            onClose={onClose}>

            <div className="flex flex-col items-center w-full gap-4">
                <h5 className="text-[22px] leading-[28px] font-semibold flex items-center justify-between w-full">
                    Перегляд сесій автомобіля: {carInfo.license_plate}
                    <IoMdClose className="cursor-pointer" onClick={onClose} size={28} />
                </h5>
                <div className="flex items-center gap-2 w-full">
                    <div
                        onClick={() => setActivePeriod(null)}
                        className={`flex items-center px-4 h-[32px] rounded-lg cursor-pointer text-[14px] leading-[17px] font-semibold ${activePeriod === null ? 'bg-[#f4de0d]' : 'bg-[#d1d5db]'}`}
                    >
                        Всі
                    </div>
                    <div
                        onClick={() => setActivePeriod(30)}
                        className={`flex items-center px-4 h-[32px] rounded-lg cursor-pointer text-[14px] leading-[17px] font-semibold ${activePeriod === 30 ? 'bg-[#f4de0d]' : 'bg-[#d1d5db]'}`}
                    >
                        За Місяць
                    </div>
                    <div
                        onClick={() => setActivePeriod(7)}
                        className={`flex items-center px-4 h-[32px] rounded-lg cursor-pointer text-[14px] leading-[17px] font-semibold ${activePeriod === 7 ? 'bg-[#f4de0d]' : 'bg-[#d1d5db]'}`}
                    >
                        За Тиждень
                    </div>
                </div>
                <div className="flex flex-col gap-4 w-full mt-2">
                    {data?.parking_sessions && data?.parking_sessions.length > 0 ? data?.parking_sessions.map((session) => (
                        <div className="flex items-center justify-between bg-gray-100 p-4 rounded-lg">
                            <span className="text-[18px] leading-[24px] font-semibold">
                                {session.parking_lot_name}
                            </span>
                            <div className="flex flex-col">
                                <span>
                                    Заїхав: {convertDateToLocalFormat(session.sessions_start)}
                                </span>
                                <span>
                                    Виїхав: {convertDateToLocalFormat(session.sessions_end)}
                                </span>
                            </div>
                        </div>
                    )) : (
                        <div className="flex items-center w-full justify-center font-semibold bg-gray-100 p-4 rounded-lg">
                            Немає сесій
                        </div>
                    )}
                </div>
            </div>
        </Modal>
    )
}

export default CarSessionsModal