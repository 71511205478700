import { useGetCarwashStatsQuery } from "../api/appApi"
import EmptyTable from "./common/EmptyTable"
import Table from "./common/Table"

import Title from "./common/Title"

const Carwash = () => {
    const { data, isLoading } = useGetCarwashStatsQuery()

    return (
        <div className="flex flex-col p-6 gap-4">
            <Title title="Паркувальні сесії з Мийки" />
            {!isLoading && (
                <>
                    {data && data.length > 0 ? (
                        <Table
                            type={"carwash"}
                            data={data}
                            columns={['license_plate', 'amount', 'start_time', 'end_time']}
                            afterValue={{ amount: '₴' }}
                        />
                    ) : (
                        <EmptyTable
                            isSearch={false}
                            title="Поки немає cесій з мийки"
                        />
                    )}
                </>
            )}
        </div>
    )
}

export default Carwash